import React from 'react';
import { Box, Typography } from '@mui/material';
import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa } from '@supabase/auth-ui-shared';
import { createClient } from '@supabase/supabase-js';
import theme from '../theme';

const supabase = createClient(process.env.REACT_APP_DB_PATH, process.env.REACT_APP_DB_PUBLIC_KEY);

const AuthPage = ({ mode }) => {
  const isSignUp = mode === 'signup';

  return (
    <Box sx={{ p: 3, width: '85%', margin: '0 auto', textAlign: 'center' }}>
      <Typography variant="h4" component="h1" gutterBottom>
        {isSignUp ? "Sign Up for Don't Forget Grandma" : "Log In to Don't Forget Grandma"}
      </Typography>
      <Auth 
      supabaseClient={supabase} 
      appearance={{
        theme: ThemeSupa,
        variables: {
          default: {
            colors: {
              brand: theme.palette.primary.main,
              brandAccent: theme.palette.secondary.main
            },
          },
        },
      }}
      providers={[]} 
      redirectTo="/" 
      view={isSignUp ? "sign_up" : "sign_in"}
      />
    </Box>
  );
};

export default AuthPage;
