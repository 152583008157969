import React from 'react';
import { Typography, Container } from '@mui/material';

const PrivacyPolicyPage = () => {
  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Privacy Policy
      </Typography>
      <Typography gutterBottom>
        Last updated: July 22, 2024<br /><br />
        The privacy of your data—and it is your data, not ours!—is a big deal to us. In this policy, we lay out: what data we collect and why; how your data is handled; and your rights with respect to your data. We promise we never sell your data: never have, never will. This policy applies to all products built and maintained by Don't Forget Grandma Pty Ltd (ABN: 57 680 285 018).<br /><br />
        This policy applies to our handling of information about site visitors, prospective customers, and customers. We refer collectively to these categories of individuals as "you" throughout this policy.<br /><br />
        If you are a California resident, additional terms relevant to you are provided at the end of this policy, including additional disclosures as required by California law.<br /><br />
        <strong>What we collect and why</strong><br /><br />
        Our guiding principle is to collect only what we need. Here’s what that means in practice:<br /><br />
        <strong>Identity and access</strong><br /><br />
        When you sign up for a DFG product, we ask for identifying information such as your name, email address, and phone number. That’s so you can personalize your new account, and we can send you product updates and other essential information. We may also send you optional surveys from time to time to help us understand how you use our products and to make improvements. With your consent, we will send you our newsletter and other updates. <br /><br />
        We’ll never sell your personal information to third parties, and we won’t use your name or company in marketing statements without your permission either.<br /><br />
        <strong>Billing information</strong><br /><br />
        If you sign up for a paid DFG product, you will be asked to provide your payment information and billing address to Stripe, who will process the payment. Credit card information is submitted directly to Stripe and doesn’t hit DFG servers. We store a record of the payment transaction, including the last 4 digits of the credit card number, for purposes of account history, invoicing, and billing support. We store your billing address so we can charge you for service, calculate any sales tax due, send you invoices, and detect fraudulent credit card transactions. We occasionally use aggregate billing information to guide our marketing efforts.<br /><br />
        <strong>Product interactions</strong><br /><br />
        We store on our servers the content that you upload or receive or maintain in your DFG accounts. This is so you can use our products as intended, such as to maintain a list of loved ones so we can remind you to reach out to them. We keep this content as long as your account is active. If you delete your account, we’ll delete the content within 60 days.<br /><br />
        <strong>General Geolocation data</strong><br /><br />
        For most of our products, we may log the full IP address used to sign up and retain that for use in mitigating future spammy signups. We also may log all account access by full IP address for security and fraud prevention purposes, and we keep this login data for as long as your product account is active.<br /><br />
        <strong>Website interactions</strong><br /><br />
        We may collect information about your browsing activity for analytics and statistical purposes such as conversion rate testing and experimenting with new product designs. This includes, for example, your browser and operating system versions, your IP address, which web pages you visited and how long they took to load, and which website referred you to us. If you have an account and are signed in, these web analytics data are tied to your IP address and user account until your account is no longer active. The web analytics we use are described further in the Advertising and Cookies section.<br /><br />
        <strong>Anti-bot assessments</strong><br /><br />
        We use CAPTCHA across our applications to mitigate brute force logins and as a means of spam protection. We have a legitimate interest in protecting our apps and the broader Internet community from credential stuffing attacks and spam. When you log into your DFG accounts and when you fill in certain forms, the CAPTCHA service evaluates various information (e.g., IP address, how long the visitor has been on the app, mouse movements) to try to detect if the activity is from an automated program instead of a human. The CAPTCHA service then provides DFG with the spam score results; we do not have access to the evaluated information.<br /><br />
        <strong>Advertising and Cookies</strong><br /><br />
        We use only essential cookies, such as those that keep track of your logged in status. <br /><br />
        A cookie is a piece of text stored by your browser. It may help remember login information and site preferences. It might also collect information such as your browser type, operating system, web pages visited, duration of visit, content viewed, and other click-stream data. You can adjust cookie retention settings and accept or block individual cookies in your browser settings, although our apps won’t work and other aspects of our service may not function properly if you turn cookies off.<br /><br />
        <strong>Voluntary correspondence</strong><br /><br />
        When you email DFG with a question or to ask for help, we keep that correspondence, including your email address, so that we have a history of past correspondence to reference if you reach out in the future.<br /><br />
        We also store information you may volunteer, for example, written responses to surveys. If you agree to a customer interview, we may ask for your permission to record the conversation for future reference or use. We will only do so with your express consent.<br /><br />
        <strong>When we access or disclose your information</strong><br /><br />
        To provide products or services you’ve requested. We use some third-party subprocessors to help run our applications and provide the Services to you, as described in the Terms of Service. We also use third-party processors for other business functions such as managing newsletter subscriptions, sending customer surveys, and providing our company storefront. <br /><br />
        We may disclose your information at your direction if you integrate a third-party service into your use of our products. For example, we may allow you, at your option, to connect your Gmail account to your DFG account so that you can log in more easily. <br /><br />
        DFG may look at your data from time-to-time, as set out in the Terms and Conditions. Some ways we will use your data include:<br /><br />
        To exclude you from seeing our ads. Where permissible by law and if you have a DFG account, we may disclose a one-way hash of your email address with ad companies to exclude you from seeing our ads.<br /><br />
        To help troubleshoot or squash a software bug. <br /><br />
        To investigate, prevent, or take action regarding restricted uses (as set out in the Terms of Service). Accessing a customer’s account when investigating potential abuse is a measure of last resort. If we discover you are using our products for a restricted purpose, we will take action as necessary, including notifying appropriate authorities where warranted.<br /><br />
        Aggregated and de-identified data. We may aggregate and/or de-identify information collected through the services. We may use de-identified or aggregated data for any purpose, including marketing or analytics.<br /><br />
        When required under applicable law. DFG is an Australian entity and data infrastructure is located primarily in the U.S., with some processing happening in India and Australia.<br /><br />
        •	Requests for user data. We may comply with government requests for data, but generally will not unless there is a legal obligation.<br /><br />
        •	If we are audited by a tax authority, we may be required to disclose billing-related information. If that happens, we will disclose only the minimum needed, such as billing addresses and tax exemption information.<br /><br />
        Finally, if DFG is acquired by or merges with another company we’ll notify you about the transfer of your user data and any new privacy policy.<br /><br />
        <strong>Your rights with respect to your information</strong><br /><br />
        At DFG, we strive to apply the same data rights to all customers, regardless of their location. Some of these rights include:<br /><br />
        •	Right to Know. You have the right to know what personal information is collected, used, shared or sold. We outline both the categories and specific bits of data we collect, as well as how they are used, in this privacy policy.<br /><br />
        •	Right of Access. This includes your right to access the personal information we gather about you, and your right to obtain information about the sharing, storage, security and processing of that information.<br /><br />
        •	Right to Correction. You have the right to request correction of your personal information.<br /><br />
        •	Right to Erasure / “To Be Forgotten”. This is your right to request, subject to certain limitations under applicable law, that your personal information be erased from our possession and, by extension, from all of our service providers. Fulfillment of some data deletion requests may prevent you from using DFG services because our applications may then no longer work. In such cases, a data deletion request may result in closing your account.<br /><br />
        •	Right to Complain. You have the right to make a complaint regarding our handling of your personal information with the appropriate supervisory authority.<br /><br />
        •	Right to Restrict Processing. This is your right to request restriction of how and why your personal information is used or processed, including opting out of sale of your personal information. (Again: we never have and never will sell your personal data.)<br /><br />
        •	Right to Object. You have the right, in certain situations, to object to how or why your personal information is processed.<br /><br />
        •	Right to Portability. You have the right to receive the personal information we have about you and the right to transmit it to another party. If you want to export data from your accounts, please contact us at hello@dfg-app.com<br /><br />
        •	Right to not Be Subject to Automated Decision-Making. You have the right to object to and prevent any decision that could have a legal or similarly significant effect on you from being made solely based on automated processes. This right is limited if the decision is necessary for performance of any contract between you and us, is allowed by applicable law, or is based on your explicit consent.<br /><br />
        •	Right to Non-Discrimination. We do not and will not charge you a different amount to use our products, offer you different discounts, or give you a lower level of customer service because you have exercised your data privacy rights. However, the exercise of certain rights may, by virtue of your exercising those rights, prevent you from using our Services.<br /><br />
        Many of these rights can be exercised by signing in and updating your account information. Please note that certain information may be exempt from such requests under applicable law. For example, we need to retain certain information in order to provide our services to you.<br /><br />
        In some cases, we also need to take reasonable steps to verify your identity before responding to a request, which may include, at a minimum, depending on the sensitivity of the information you are requesting and the type of request you are making, verifying your name and email address. If we are unable to verify you, we may be unable to respond to your requests. If you have questions about exercising these rights or need assistance, please contact us at hello@dfg-app.com. If an authorized agent is corresponding on your behalf, we will need written consent with a signature from the account holder before proceeding.<br /><br />
        Depending on applicable law, you may have the right to appeal our decision to deny your request, if applicable. We will provide information about how to exercise that right in our response denying the request. You also have the right to lodge a complaint with a supervisory authority. If you are in the EU or UK, you can contact your data protection authority to file a complaint or learn more about local privacy laws.<br /><br />
        <strong>How we secure your data</strong><br /><br />
        All data is encrypted when transmitted from our servers to your browser. In addition, we go to great lengths to secure your data at rest including encryption where possible. To keep data readily available to send to you when you use our services, data at rest is occasionally kept unencrypted.<br /><br />
        What happens when you delete content in your product accounts<br /><br />
        In many of our applications, we give you the option to trash content. Anything you trash in your product accounts while they are active may be kept in an accessible trash can for about 25 days. After that time, the trashed content cannot be accessed via the application and we are not able to retrieve it for you. The trashed content may remain on our active servers for another 30 days, and copies of the content may be held in backups of our application databases for up to another 30 days after that. Altogether, any content trashed in your product accounts should be purged from all of our systems and logs within 90 days.<br /><br />
        If you choose to cancel your account, your content will become immediately inaccessible and should be purged from our systems in full within 60 days. This applies both for cases when an account owner directly cancels and for auto-canceled accounts. Please refer to our Terms of Service for more details.<br /><br />
        <strong>Data retention</strong><br /><br />
        We keep your information for the time necessary for the purposes for which it is processed. The length of time for which we retain information depends on the purposes for which we collected and use it and your choices, after which time we may delete and/or aggregate it. We may also retain and use this information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements. Through this policy, we have provided specific retention periods for certain types of information.<br /><br />
        <strong>Location of site and data</strong><br /><br />
        DFG operates primarily from Australia. If you are located in the European Union, UK, or elsewhere outside of the United States, please be aware that any information you provide to us will be transferred to and stored in the United States, India and Australia. Data will be transferred to these locations due to server locations for Zoho and Amazon Web Servers. International data transfers are done through encrypted connections. By using our websites or Services and/or providing us with your personal information, you consent to this transfer.<br /><br />
        <strong>Changes and questions</strong><br /><br />
        We may update this policy as needed to comply with relevant regulations and reflect any new practices. Whenever we make a significant change to our policies, we will refresh the date at the top of this page and take any other appropriate steps to notify users.<br /><br />
        Have any questions, comments, or concerns about this privacy policy, your data, or your rights with respect to your information? Please get in touch by emailing us at hello@dfg-app.com and we’ll be happy to try to answer them!<br /><br />
        <br /><br />
        <strong id="cal-residents">For Californian Residents</strong><br /><br />
        If you are a California resident, the California Consumer Privacy Act, requires us to provide some additional information to California residents. This Section only applies to you if you are a California resident, although please note that this information and the rights afforded herein are the same as offered to our other users in our main Privacy Policy. This Section does not apply to personal information we collect from our employees and job applicants in their capacity as employees and job applicants.<br /><br />
        These activities are as follows:<br /><br />
        <table class="tg"><thead>
          <tr>
            <th class="tg-amwm">Category of personal information</th>
            <th class="tg-amwm">Purposes of use</th>
            <th class="tg-amwm">Categories of Third Parties to Which We Discloses this Personal Information</th>
            <th class="tg-amwm">Categories of Third Parties to Which We "Share" and "Sell" this Personal Information for Advertising/ Analytics Purposes</th>
          </tr></thead>
        <tbody>
          <tr>
            <td class="tg-0lax">Contact information (such as your full name, phone number, email address, country of residence)</td>
            <td class="tg-0lax">Provide the Services; Communicate with you; Analyze use of and improve the services; With your consent; Comply with law or defend our legal rights; Security/fraud prevention</td>
            <td class="tg-0lax">Affiliated entities; Service providers; Entities for legal purposes</td>
            <td class="tg-0lax">We do not share/sell</td>
          </tr>
          <tr>
            <td class="tg-0lax">Customer service interaction information (including optional surveys and when you ask for help)</td>
            <td class="tg-0lax">Provide the Services; Communicate with you; Analyze use of and improve the services; With your consent; Comply with law or defend our legal rights; Security/fraud prevention</td>
            <td class="tg-0lax">Affiliated entities; Service providers; Entities for legal purposes</td>
            <td class="tg-0lax">We do not share/sell</td>
          </tr>
          <tr>
            <td class="tg-0lax">Product interaction information (e.g. your contacts list and notes)</td>
            <td class="tg-0lax">Provide the Services; Communicate with you; Analyze use of and improve the services; With your consent; Comply with law or defend our legal rights; Security/fraud prevention</td>
            <td class="tg-0lax">Affiliated entities; Service providers; Entities for legal purposes</td>
            <td class="tg-0lax">We do not share/sell</td>
          </tr>
          <tr>
            <td class="tg-0lax">Internet network and device information (such as mobile device information, IP address, and information about your interaction with the services)</td>
            <td class="tg-0lax">Provide the Services; Analyze use of and improve the services; With your consent; Comply with law or defend our legal rights; Security/fraud prevention</td>
            <td class="tg-0lax">Affiliated entities; Service providers; Entities for legal purposes;</td>
            <td class="tg-0lax">We do not share/sell</td>
          </tr>
          <tr>
            <td class="tg-0lax">Login information (such as your username and password)</td>
            <td class="tg-0lax">Provide the Services; Comply with law or defend our legal rights; Security/fraud prevention; Comply with law or defend our legal rights</td>
            <td class="tg-0lax">Affiliated entities; Service providers; Entities for legal purposes</td>
            <td class="tg-0lax">We do not share/sell</td>
          </tr>
          <tr>
            <td class="tg-0lax">Professional or employment information (such as the name and address of the company you work for and your title)</td>
            <td class="tg-0lax">Provide the Services; Communicate with you; Analyze use of and improve the services; With your consent; Comply with law or defend our legal rights; Security/fraud prevention</td>
            <td class="tg-0lax">Affiliated entities; Service providers; Entities for legal purposes;</td>
            <td class="tg-0lax">We do not share/sell</td>
          </tr>
          <tr>
            <td class="tg-0lax">Other information (any other information you choose to provide directly to us)</td>
            <td class="tg-0lax">Provide the Services; Communicate with you; Analyze use of and improve the services; With your consent; Comply with law or defend our legal rights; Security/fraud prevention</td>
            <td class="tg-0lax">Affiliated entities; Service providers; Entities for legal purposes;</td>
            <td class="tg-0lax">We do not sell/share</td>
          </tr>
        </tbody></table>
        <br /><br />
        For more information about each category of personal information, purpose of use, and third parties to which we disclose personal information, please see the "What we collect and why," and "When we access or disclose your information" sections of our Privacy Policy.<br /><br />
        <strong>Your Choices Regarding “Sharing” and “Selling”</strong>: You have the right to opt out of our sale/sharing of your personal information for purposes of online analytics and advertising. Currently, we do not sell or share your data as defined by the CCPA and we have not done so over the past 12 months from the effective date of this Privacy Policy.<br /><br />
        <strong>Other CCPA Rights</strong>. If we ever offer any financial incentives in exchange for your personal information, we will provide you with appropriate information about such incentives.<br /><br />
        The CCPA also allows you to limit the use or disclosure of your “sensitive personal information” (as defined in the CCPA) if your sensitive personal information is used for certain purposes. Please note that we do not use or disclose sensitive personal information other than for business purposes for which you cannot opt out under the CCPA.<br /><br />
        Please see the “Your rights with respect to your information” section of our Policy above for information about the additional rights you have with respect to your personal information under California law and how to exercise them.<br /><br />
        Retention of Your Personal Information. Please see the “Data Retention” section of our Privacy Policy for more information.<br /><br />
        <strong>Shine the Light Disclosure</strong><br /><br />
        The California "Shine the Light" law gives residents of California the right under certain circumstances to request information from us regarding the manner in which we disclose certain categories of personal information (as defined in the Shine the Light law) with third parties for their direct marketing purposes. We currently do not disclose your personal information to third parties for their own direct marketing purposes.<br /><br />
        <br />
        Adapted from the Basecamp open-source policies / CC BY 4.0
      </Typography>
    </Container>
  );
};

export default PrivacyPolicyPage;
