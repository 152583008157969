import React, { useState, useMemo } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TableSortLabel, Box } from '@mui/material';

function ContactsTable({ contacts, onEdit, onDelete }) {
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  const requestSort = (key) => {
    let direction = 'desc';
    if (sortConfig.key === key && sortConfig.direction === 'desc') {
      direction = 'asc';
    }
    setSortConfig({ key, direction });
  };

  const sortedContacts = useMemo(() => {
    let sortableItems = [...contacts];
    if (sortConfig.key !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] === null) return 1;
        if (b[sortConfig.key] === null) return -1;
        if (a[sortConfig.key] === null && b[sortConfig.key] === null) return 0;
        
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [contacts, sortConfig]);


  return (
    <TableContainer component={Paper}>
      <Table aria-label="contacts table">
        <TableHead>
          <TableRow>
            <TableCell style={{ backgroundColor: '#f2e8cf' }}>
              <TableSortLabel
                active={sortConfig.key === 'contact_name'}
                direction={sortConfig.key === 'contact_name' ? sortConfig.direction : 'asc'}
                onClick={() => requestSort('contact_name')}
              >
                Contact Name
              </TableSortLabel>
            </TableCell>
            <TableCell style={{ backgroundColor: '#f2e8cf' }}>
              <TableSortLabel
                active={sortConfig.key === 'birthday'}
                direction={sortConfig.key === 'birthday' ? sortConfig.direction : 'asc'}
                onClick={() => requestSort('birthday')}
              >
                Birthday
              </TableSortLabel>
            </TableCell>
            <TableCell style={{ backgroundColor: '#f2e8cf' }}>
              <TableSortLabel
                active={sortConfig.key === 'contact_frequency'}
                direction={sortConfig.key === 'contact_frequency' ? sortConfig.direction : 'asc'}
                onClick={() => requestSort('contact_frequency')}
              >
                Contact every:
              </TableSortLabel>
            </TableCell>
            <TableCell style={{ backgroundColor: '#f2e8cf' }}>Notes</TableCell>
            <TableCell style={{ backgroundColor: '#f2e8cf' }}></TableCell>
          </TableRow> 
        </TableHead>
        <TableBody>
          {sortedContacts.map((contact) => (
            <TableRow key={contact.contact_id}>
              <TableCell>{contact.contact_name || ''}</TableCell>
              <TableCell>{contact.birthday || ''}</TableCell>
              <TableCell>{contact.contact_frequency || ''} days</TableCell>
              <TableCell>{contact.notes || ''}</TableCell>
              <TableCell>
                <Box display="flex" justifyContent="flex-end">
                  <Button variant="outlined" color="primary" onClick={() => onEdit(contact)}>Edit</Button> &nbsp;
                  <Button variant="outlined" color="secondary" onClick={() => onDelete(contact.contact_id)}>Delete</Button>
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ContactsTable;
