import React from 'react';
import { Typography, Container } from '@mui/material';

const TermsOfServicePage = () => {
  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Terms of Service
      </Typography>
      <Typography gutterBottom>
        Last updated: July 22, 2024<br /><br /><br />
        From everyone at Don’t Forget Grandma, thank you for using our products! We build them to help you be your best. Because we don’t know every one of our customers personally, we have to put in place some Terms of Service to help keep the ship afloat.<br /><br />
        When we say “we”, “our”, or “us” in this document, we are referring to the company Don't Forget Grandma Pty Ltd (ABN: 57 680 285 018).<br /><br />
        When we say “Services”, we mean our website, dfg-app.com, and any product created and maintained by Don’t Forget Grandma Pty Ltd (“DFG”), whether delivered within a web browser, desktop application, mobile application, or another format.<br /><br />
        When we say “You” or “your”, we are referring to the people or organizations that own an account with one or more of our Services. <br /><br />
        We may update these Terms of Service ("Terms") in the future. Whenever we make a significant change to our policies, we will refresh the date at the top of this page. Please refer to this page often to monitor for changes to the policy. Where there are changes, we will make our best attempt to notify you by email or through your account online.<br /><br />
        When you use our Services, now or in the future, you are agreeing to the latest Terms. There may be times where we do not exercise or enforce a right or provision of the Terms; however, that does not mean we are waiving that right or provision. <strong>These Terms do contain a limitation of our liability. These terms include a requirement for binding arbitration.</strong><br /><br />
        If you violate any of the Terms, we may terminate your account. That’s a broad statement and it means you need to place a lot of trust in us. We do our best to deserve that trust by keeping an open door to your feedback. (you can email us at hello@dfg-app.com)<br /><br />
        <strong>Account Terms</strong><br /><br />
        1.	You are responsible for maintaining the security of your account and password, including by not using a password on DFG that you have used elsewhere on the internet, and by ensuring your password is complex (long, with a variety of character types, etc.). DFG cannot and will not be liable for any loss or damage from your failure to comply with this security obligation. <br /><br />
        2.	You must only use the service as intended and must keep your usage at a reasonable level. This means using the service as a human – any programmatic use of DFG is strictly prohibited. Manipulating the system, for instance to add large (1000+) numbers of contacts in one sitting, send multiple SMS validation messages, or otherwise, is prohibited.<br /><br />
        3.	You are responsible for all content posted to and activity that occurs under your account, including content posted by and activity of any users in your account.<br /><br />
        4.	You must be a human. Accounts registered by “bots” or other automated methods are not permitted.<br /><br />
        <strong>Payment, Refunds, and Plan Changes</strong><br /><br />
        1.	For paid Services that offer a free trial, we explain the length of trial when you sign up. After the trial period, you need to pay in advance to keep using the Service. If you do not pay, we will freeze your account and it will be inaccessible until you make payment. We may delete your account at any point after it has become unpaid. <br /><br />
        2.	If you are upgrading to a more expensive plan, we will charge your card immediately and your billing cycle starts on the day of upgrade. If you are downgrading, your account will remain on the more expensive plan until the current billing cycle ends, at which point your account will be renewed onto the new plan.<br /><br />
        3.	All fees are exclusive of all taxes, levies, or duties imposed by taxing authorities. Where required, we will collect those taxes on behalf of the taxing authority and remit those taxes to taxing authorities. Otherwise, you are responsible for payment of all taxes, levies, or duties.<br /><br />
        4.	Refunds are not available following the commencement of the service. If you have signed up but not configured your account (i.e. added no contacts, and not received any SMS or email notifications to reach out to your loved ones) than we may offer a refund. <br /><br />
        <strong>Cancellation and Termination</strong><br /><br />
        1.	You are solely responsible for properly canceling your account and your subscription. Within each of our Services, we provide a settings page that allows you to modify your subscription on Stripe. An email or phone request to cancel your account is not automatically considered cancellation. If you need help canceling your account, you can always contact our Support team at hello@dfg-app.com.<br /><br />
        2.	Upon cancellation of your subscription, we will commence deleting your content. Within 30 days, all content will be permanently deleted from active systems and logs. Within 60 days, all content will be permanently deleted from our backups. We cannot recover this information once it has been permanently deleted. If you want to export any data before your account is canceled, please ensure you do so.<br /><br />
        3.	If you cancel the Service before the end of your current paid up month, your cancellation will take effect at the end of the current billing cycle and you will not be charged again. If you wish to stop receiving reminder SMS or emails about your loved ones, please delete all contacts from your account. We do not prorate unused time in the last billing cycle. <br /><br />
        4.	We have the right to suspend or terminate your account and refuse any and all current or future use of our Services for any reason at any time. Suspension means you and any other users on your account will not be able to access the account or any content in the account. Termination will furthermore result in the deletion of your account or your access to your account, and the forfeiture and relinquishment of all content in your account. We also reserve the right to refuse the use of the Services to anyone for any reason at any time. We have this clause because some people do nefarious thins on the internet. See the Use Restrictions below for some examples of scenarious in which we may immediately terminate or suspend your account.<br /><br />
        5.	Verbal, physical, written or other abuse (including threats of abuse or retribution) of a DFG employee or officer will result in immediate account termination.<br /><br />
        <strong>Use Restrictions</strong><br /><br />
        When you use any of DFG’s Services, you acknowledge that you may not:<br /><br />
        •	Collect or extract information and/or user data from accounts which do not belong to you.<br /><br />
        •	Circumvent, disable, or otherwise interfere with security-related features of the Services.<br /><br />
        •	Trick, defraud, or mislead us or other users, including but not limited to making false reports or impersonating another user.<br /><br />
        •	Upload or transmit (or attempt to upload or to transmit) viruses or any type of malware, or information collection mechanism, including 1×1 pixels, web bugs, cookies, or other similar devices.<br /><br />
        •	Interfere with, disrupt, or create an undue burden on the Services or the networks or the Services connected.<br /><br />
        •	Harass, annoy, intimidate, or threaten others, or any of our employees engaged in providing any portion of the Services to you.<br /><br />
        •	Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Services.<br /><br />
        •	Use the Services in a manner inconsistent with applicable laws or regulations.<br /><br />
        Accounts found to be in violation of any of the above are subject to cancellation without prior notice.<br /><br />
        How to report abuse<br /><br />
        Violations can be reported by emailing hello@dfg-app.com and should include detailed information about the account, the content or behavior you are reporting, and how you found it, including URLs or screenshots. If you need a secure file transfer, let us know and we will send you a link.. <br /><br />
        <strong>Modifications to the Service and Prices</strong><br /><br />
        1.	We reserve the right at any time to modify or discontinue, temporarily or permanently, any part of our Services with or without notice.<br /><br />
        2.	Sometimes we change the pricing structure for our products. When we do that, we may at our sole discretion exempt existing customers from those changes. However, if we choose to change the prices for existing customers, we will give at least 30 days notice and will notify you via the email address on record. We may also post a notice about changes on our websites or the affected Services themselves.<br /><br />
        <strong>Uptime, Security, and Privacy</strong><br /><br />
        1.	Your use of the Services is at your sole risk. We provide these Services on an “as is” and “as available” basis. We do not offer service-level agreements for most of our Services, but do take uptime of our applications seriously. <br /><br />
        2.	You must provide a valid, functioning mobile phone number for SMS notifications. Failure to deliver due to you providing invalid phone number would be unfortunate, but we will send to the number you share with us (and which is validated during the sign-up process). You are responsible for keeping that phone number active and in service.<br /><br />
        3.	We reserve the right to temporarily disable your account if your usage significantly exceeds the average usage of other customers of the Services.<br /><br />
        4.	When you use our Services, you entrust us with your data. We take that trust to heart. You agree that Don’t Forget Grandma may process your data as described in our Privacy Policy . We have access to your data, and may use it for reasons such as:<br /><br />
        •	To help you with support requests you make.<br /><br />
        •	On the rare occasions when an error occurs that stops an automated process partway through. <br /><br />
        •	To safeguard Don’t Forget Grandma. We’ll look at logs and metadata as part of our work to ensure the security of your data and the Services as a whole. If necessary, we may also access accounts as part of an investigation.<br /><br />
        •	To test and build new services or enhance existing functionality.<br /><br />
        •	To the extent required by applicable law, or to cooperate with requests from law enforcement or other government agencies at our discretion.<br /><br />
        5.	We use third party vendors and hosting partners to provide the necessary hardware, software, networking, storage, and related technology required to run the Services. These include: Supabase, Twilio, Zoho, and Vercel.<br /><br />
        6.	Under the California Consumer Privacy Act (“CCPA”), Don’t Forget Grandma is a “service provider”, not a “business” or “third party”, with respect to your use of the Services. That means we process any data you share with us only for the purpose you signed up for and as described in these Terms, the Privacy Policy and other policies as may be created. We do not retain, use, disclose, or sell any of that information for any other commercial purposes unless we have your explicit permission. And on the flip-side, you agree to comply with your requirements under the CCPA and not use DFG’s Services in a way that violates the regulations.<br /><br />
        <strong>Copyright and Content Ownership</strong><br /><br />
        1.	All content posted on the Services must comply with U.S. copyright law. <br /><br />
        2.	You give us a license to use the content posted by you and your users in order to provide the Services to you, but we claim no ownership rights over those materials. <br /><br />
        3.	We do not pre-screen content, but we reserve the right (but not the obligation) in our sole discretion to refuse or remove any content that is available via the Service.<br /><br />
        4.	DFG or its licensors own all right, title, and interest in and to the Services, including all intellectual property rights therein, and you obtain no ownership rights in the Services as a result of your use. You may not duplicate, copy, or reuse any portion of the HTML, CSS, JavaScript, or visual design elements without express written permission from DFG. You must request permission to use DFG’s logos or any Service logos for promotional purposes. Please email us at hello@dfg-app.com with requests to use logos. We reserve the right to rescind any permissions if you violate these Terms.<br /><br />
        5.	You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Services, use of the Services, or access to the Services without the express written permission of DFG.<br /><br />
        <strong>Features and Bugs</strong><br /><br />
        We design our Services with care, based on our own experience and the experiences of customers who share their time and feedback. However, there is no such thing as a service that pleases everybody. We make no guarantees that our Services will meet your specific requirements or expectations.<br /><br />
        We also test all of our features before shipping them. As with any software, our Services inevitably have some bugs. We track the bugs reported to us and work through priority ones, especially any related to security or privacy. Not all reported bugs will get fixed and we don’t guarantee error-free Services.<br /><br />
        <strong>Liability</strong><br /><br />
        We mention liability throughout these Terms but to put it all in one section:<br /><br />
        <strong>You expressly understand and agree that DFG shall not be liable, in law or in equity, to you or to any third party for any direct, indirect, incidental, lost profits, special, consequential, punitive or exemplary damages, including, but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses (even if DFG has been advised of the possibility of such damages), resulting from: (i) the use or the inability to use the Services; (ii) the cost of procurement of substitute goods and services resulting from any goods, data, information or services purchased or obtained or messages received or transactions entered into through or from the Services; (iii) unauthorized access to or alteration of your transmissions or data; (iv) statements or conduct of any third party on the service; (v) or any other matter relating to these Terms or the Services, whether as a breach of contract, tort (including negligence whether active or passive), or any other theory of liability.<br /><br /></strong>
        In other words: choosing to use our Services does mean you are making a bet on us. If the bet does not work out, that’s on you, not us. We do our best to be as safe a bet as possible through careful management of the business; and in general caring a lot. If you choose to use our Services, thank you for betting on us. <br /><br />
        Limitation of Liability. Notwithstanding the above, DFG shall not be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of profits, data, or use, incurred by the User or any third party, whether in an action in contract or tort, even if DFG has been advised of the possibility of such damages. The total liability of DFG for any claim arising out of or relating to these terms or the Service, except where the previous two paragraphs apply, shall not exceed the amount paid by the User to DFG in the 12 months preceding the claim.<br /><br />
        <strong>Severability</strong><br /><br />
        If any provision of these Terms is held to be invalid or unenforceable, such provision shall be struck and the remaining provisions shall be remain in force to the fullest extent under law. <br /><br />
        If a provision is invalid or unenforceable, the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision.<br /><br />
        <strong>Governing Law and Jurisdiction</strong><br /><br />
        These Terms shall be governed by and construed in accordance with the laws of New South Wales, Australia, without regard to its conflict of law principles. Any legal action or proceeding arising under these Terms will be brought exclusively in the courts located in Sydney, New South Wales, and the parties hereby irrevocably consent to the personal jurisdiction and venue therein.<br /><br />
        <strong>Arbitration</strong><br /><br />
        Any dispute, controversy, or claim arising out of or relating to these Terms or the breach thereof shall be settled by binding arbitration, and judgment upon the award rendered by the arbitrator(s) may be entered in any court having jurisdiction thereof. The place of arbitration shall be Sydney, New South Wales.<br /><br />
        <strong>Entire Agreement</strong><br /><br />
        These Terms, together with our Privacy Policy and any other legal notices published by us on our Services, constitute the entire agreement between you and us regarding your use of our Services. Any modifications to these Terms must be in writing and signed by an authorized representative of DFG.<br /><br />
        If you have a question about any of these Terms, please contact our Support team at hello@dfg-app.com<br /><br />
        <br /><br />
        Adapted from the Basecamp open-source policies / CC BY 4.0<br />
        <br />
        </Typography>
    </Container>
  );
};

export default TermsOfServicePage;
