import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import MenuIcon from '@mui/icons-material/Menu';

const LayoutContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  width: '100%',
  overflowX: 'hidden',
}));

const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(2),
  background: theme.palette.primary.dark,
  color: theme.palette.common.white,
  width: '100%',
  boxSizing: 'border-box',
}));

const MainContent = styled(Box)(({ theme }) => ({
  flex: '1',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  overflowX: 'hidden',
  boxSizing: 'border-box',
}));

const Footer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  color: theme.palette.common.white,
  padding: theme.spacing(4),
  textAlign: 'center',
  marginTop: 'auto',
  width: '100%',
  boxSizing: 'border-box',
}));

const Layout = ({ children, isLoggedIn, onLogout }) => {
  const [prevPath, setPrevPath] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== '/settings') {
      setPrevPath(location.pathname);
    }
  }, [location]);

  const handleSettingsClick = () => {
    if (location.pathname === '/settings') {
      navigate(prevPath || '/');
    } else {
      navigate('/settings');
    }
  };

  return (
    <LayoutContainer className="full-width hidden-overflow">
      <Header>
        <Box component={Link} to="/" style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'flex-end' }}>
          <Typography
            variant="h6"
            className="header-title"
            sx={{
              fontFamily: '"Honk", system-ui',
              fontOpticalSizing: 'auto',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariationSettings: '"MORF" 15, "SHLN" 50',
              fontSize: '2rem',
              display: 'flex',
              alignItems: 'flex-end',
            }}
          >
            Don't Forget Grandma
          </Typography>
          <Typography
            variant="caption"
            sx={{
              fontSize: '0.75rem',
              fontWeight: 'bold',
              marginLeft: 1,
              alignSelf: 'flex-end',
            }}
          >
            BETA
          </Typography>
        </Box>
        <Box>
        {isLoggedIn && location.pathname !== '/new-user' && (
            <>
              <IconButton
                size="large"
                edge="end"
                color="inherit"
                aria-label="settings"
                sx={{ mr: 2 }}
                onClick={handleSettingsClick}
              >
                <MenuIcon />
              </IconButton>
              <Button color="inherit" onClick={onLogout}>
                Log Out
              </Button>
            </>
          )}
          {isLoggedIn && location.pathname === '/new-user' && (
            <>
              <Button color="inherit" onClick={onLogout}>
                Log Out
              </Button>
            </>
          )}
          {!isLoggedIn && (
            <Button color="inherit" component={Link} to="/login">
              Login
            </Button>
          )}
        </Box>
      </Header>
      <MainContent>
        {children}
      </MainContent>
      <Footer className="footer">
        <Typography variant="body2">
          &copy; 2024 Don't Forget Grandma Pty Ltd. All rights reserved.
        </Typography>
        <Box mt={2} sx={{ fontSize: '0.75rem' }}>
          <a href="/privacy-policy">Privacy Policy</a>
          {' | '}
          <a href="/terms-of-service">Terms of Service</a>
          {' | '}
          <HashLink to="/privacy-policy#cal-residents">California Privacy Notice</HashLink>
        </Box>
      </Footer>
    </LayoutContainer>
  );
};

export default Layout;
