import React, { useState, useEffect } from 'react';
import { Button, Box, TextField, Paper, useTheme } from '@mui/material';

function ContactForm({ onSubmit, editingContact, onCancel }) {
  const theme = useTheme();
  const [contact, setContact] = useState({
    contact_name: '',
    birthday: '',
    contact_frequency: '',
    notes: ''
  });

  useEffect(() => {
    if (editingContact) {
      setContact(editingContact);
    } else {
      resetForm();
    }
  }, [editingContact]);

  const resetForm = () => {
    setContact({ contact_name: '', birthday: '', contact_frequency: '', notes: '' });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setContact(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmitForm = (event) => {
    event.preventDefault();

    const submittedContact = {
      ...contact,
      birthday: contact.birthday || null
    };
  
    onSubmit(submittedContact);
    resetForm();
    if (onCancel) onCancel(); // Hide form after submission
  };

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const isValidDate = (dateStr) => {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    if (dateStr.match(regex) === null) {
      return false;
    }
    const date = new Date(dateStr);
    return date instanceof Date && !isNaN(date);
  };

  const isPositiveInteger = (value) => {
    // First, check if the value is a number and not a mixed string (e.g., "123abc")
    if (!/^\d+$/.test(value)) {
      return false;
    }
    const number = parseFloat(value);
    // Check if the number is an integer and greater than zero
    return Number.isInteger(number) && number > 0;
  };
  
  return (
    <Box sx={{ position: 'fixed', top: '10%', left: '50%', transform: 'translateX(-50%)', width: 'auto', p: 2, zIndex: 100 }}>
      <Paper elevation={6} sx={{ p: 3, display: 'flex', flexDirection: 'column', gap: 2, borderRadius: '10px' }}>
        <TextField
          label="contact name"
          name="contact_name"
          value={contact.contact_name}
          onChange={handleChange}
          required
          fullWidth
        />
        <TextField
          label="contact frequency (days)"
          name="contact_frequency"
          type="number"
          value={contact.contact_frequency}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
          error={!isPositiveInteger(contact.contact_frequency) && contact.contact_frequency !== ''}
          helperText={!contact.contact_frequency ? "Required" : ""}
        />
        {isSafari ? (
          <TextField
            label="birthday"
            name="birthday"
            type="text"
            placeholder="YYYY-MM-DD"
            value={contact.birthday}
            onChange={handleChange}
            fullWidth
            margin="normal"
            error={contact.birthday && !isValidDate(contact.birthday)}
            helperText={contact.birthday && !isValidDate(contact.birthday) ? "Invalid date, format YYYY-MM-DD" : ""}
          />
        ) : (
          <TextField
            label="birthday"
            name="birthday"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={contact.birthday}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
        )}
        <TextField
          label="notes"
          name="notes"
          multiline
          rows={4}
          value={contact.notes}
          onChange={handleChange}
          fullWidth
        />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
          <Button onClick={onCancel} color="secondary" sx={{ color: theme.palette.secondary.main }}>
            Cancel
          </Button>
          <Button 
            variant="contained" 
            color="primary" 
            disabled={!(isPositiveInteger(contact.contact_frequency) && contact.contact_name.trim() !== '')}
            onClick={handleSubmitForm}>
            {editingContact ? 'Update Contact' : 'Add Contact'}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}

export default ContactForm;
