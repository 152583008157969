import React, { useState, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import theme from './theme';
import reportWebVitals from './reportWebVitals';
import ContactsTable from './components/ContactsTable';
import ContactForm from './components/ContactForm';
import UserInfoForm from './components/UserInfoForm';
import UserInfoEditForm from './components/UserInfoEditForm';
import AppRoutes from './components/AppRoutes';
import { createClient } from '@supabase/supabase-js';
import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa } from '@supabase/auth-ui-shared';
import { Typography, Box, Button, CircularProgress, Grid, Paper } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import LandingPage from './components/LandingPage';
import Layout from './components/Layout';
import Settings from './components/Settings'; // Ensure you have a Settings component
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import AuthPage from './components/AuthPage';
import NewUserPage from './components/NewUserPage'; // Import the NewUserDetails component

const supabase = createClient(process.env.REACT_APP_DB_PATH, process.env.REACT_APP_DB_PUBLIC_KEY);

function App() {
  const [session, setSession] = useState(null);
  const [contacts, setContacts] = useState([]);
  const [editingContact, setEditingContact] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [isEditingUserInfo, setIsEditingUserInfo] = useState(false);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [showAuth, setShowAuth] = useState(false);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate(); // Move useNavigate inside the App component

  const handleShowAddForm = () => {
    setEditingContact(null);
    setIsFormVisible(true);
  };

  const handleShowEditForm = (contact) => {
    setEditingContact(contact);
    setIsFormVisible(true);
  };

  const fetchContacts = useCallback(async () => {
    if (!session) return;

    const { data, error } = await supabase.from('contacts').select('*').eq('user_id', session.user.id);

    if (error) {
      console.error('Error fetching contacts:', error);
    } else {
      setContacts(data);
    }
  }, [session]);

  useEffect(() => {
    if (session) {
      fetchContacts();
    }
  }, [session, fetchContacts]);

  const fetchUserInfo = useCallback(async (userId) => {
    const { data, error } = await supabase.from('users').select('user_name, user_mobile_number, desired_contact_time, user_tz').eq('user_id', userId).single();

    if (error) {
      console.error('Error fetching user info:', error);
      return null;
    }

    return data;
  }, []);

  useEffect(() => {
    if (session) {
      setLoading(true);
      fetchUserInfo(session.user.id).then((data) => {
        setUserInfo(data);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [session, fetchUserInfo]);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  const handleSubmit = async (contactData) => {
    if (!session) return;
    setIsFormVisible(false);
    const { error } = await supabase.from('contacts').upsert(contactData);
    if (error) {
      console.error('Error saving contact:', error);
    } else {
      fetchContacts();
    }
  };

  const handleCancelEdit = () => {
    setIsFormVisible(false);
  };

  const handleEdit = (contact) => {
    setEditingContact(contact);
  };

  const handleLogout = async () => {
    await supabase.auth.signOut();
    setSession(null);
    setUserInfo(null);
    setContacts([]);
    navigate('/');
  };

  const handleDelete = async (contact_id) => {
    const { error } = await supabase.from('contacts').delete().match({ contact_id });

    if (error) {
      console.error('Error deleting contact:', error);
    } else {
      fetchContacts();
    }
  };

  const redirectToStripeBilling = async () => {
    const stripeBillingUrl = `https://billing.stripe.com/p/login/4gw6sl1pAa3d3oQ9AA?prefilled_email=${encodeURIComponent(session.user.email)}`;
    window.location.href = stripeBillingUrl;
  };

  const handleUserInfoSubmit = async (userInfo) => {
    const { error } = await supabase.from('users').upsert({ ...userInfo, user_id: session.user.id });

    if (error) {
      console.error('Error saving user info:', error);
    } else {
      setUserInfo(userInfo);
      navigate('/contacts');
    }
  };

  const handleEditUserInfo = () => {
    setIsEditingUserInfo(true);
  };

  const handleUserInfoUpdate = async (updatedInfo) => {
    const { error } = await supabase.from('users').upsert({ ...updatedInfo, user_id: session.user.id });

    if (error) {
      console.error('Error saving user info:', error);
    } else {
      setUserInfo(updatedInfo);
      setIsEditingUserInfo(false);
    }
  };

  const handleSignUp = () => {
    setShowAuth(true);
    navigate('/signup');
  };
  
  const handleLogin = () => {
    setShowAuth(true);
    navigate('/login');
  };
  
  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Layout isLoggedIn={!!session} onLogin={() => setShowAuth(true)} onLogout={handleLogout}>
      <AppRoutes
        session={session}
        userInfo={userInfo}
        isFormVisible={isFormVisible}
        handleSignUp={handleSignUp}
        handleUserInfoSubmit={handleUserInfoSubmit}
        handleLogin={handleLogin}
        handleLogout={handleLogout}
        handleSubmit={handleSubmit}
        editingContact={editingContact}
        handleCancelEdit={handleCancelEdit}
        handleShowAddForm={handleShowAddForm}
        contacts={contacts}
        handleShowEditForm={handleShowEditForm}
        handleDelete={handleDelete}
        isEditingUserInfo={isEditingUserInfo}
        handleEditUserInfo={handleEditUserInfo}
        handleUserInfoUpdate={handleUserInfoUpdate}
        redirectToStripeBilling={redirectToStripeBilling}
      />
    </Layout>
  );
}

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <AppWrapper />
    </ThemeProvider>
  </React.StrictMode>
);

reportWebVitals();
