import React, { useState } from 'react';
import { Typography, Box, Button, Paper, Grid, Card, CardContent, Container, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled, useTheme } from '@mui/system';
import { ReactComponent as HeroImage } from './hero.svg'; // Adjust the path if necessary
import Carousel from 'react-material-ui-carousel';

const HeroSection = styled(Box)(({ theme }) => ({
  background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
  color: theme.palette.common.white,
  height: '100vh', // Use full viewport height
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  padding: 0,
  margin: 0,
  width: '100%',
  position: 'relative',
  overflow: 'hidden',
  [theme.breakpoints.down('sm')]: {
    height: '100vh', // Full viewport height for smaller screens
  },
}));

const HeroText = styled(Box)(({ theme }) => ({
  padding: theme.spacing(6),
  borderRadius: '10px',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
  maxWidth: '600px',
  background: 'rgba(0, 0, 0, 0.5)',
  textAlign: 'center',
  zIndex: 1,
}));

const MessageBubble = styled(Box)(({ theme }) => ({
  backgroundColor: '#007AFF', // iMessage blue color
  borderRadius: '20px',
  padding: theme.spacing(1, 2), // Reduced padding
  margin: theme.spacing(2, 0),
  display: 'block',
  fontStyle: 'italic',
  color: theme.palette.common.white,
  maxWidth: 'fit-content',
  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
  position: 'relative',
  '&:after': {
    content: '""',
    position: 'absolute',
    bottom: '-10px', // Adjust to position the triangle correctly
    left: '20px', // Adjust to position the triangle correctly
    width: 0,
    height: 0,
    border: '10px solid transparent',
    borderTopColor: '#007AFF',
  },
}));

const Section = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8, 2),
  textAlign: 'center',
  width: '100%',
}));

const FullWidthSection = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(8, 2),
  textAlign: 'center',
}));

const TestimonialsSection = styled(Box)(({ theme }) => ({
  backgroundColor: '#f9f9f9',
  padding: theme.spacing(8, 2),
  textAlign: 'center',
}));

const CustomButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
  },
  transition: 'transform 0.3s, background-color 0.3s',
  '&:hover': {
    transform: 'scale(1.05)',
  },
}));

const CustomCard = styled(Card)(({ theme }) => ({
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'translateY(-10px)',
    boxShadow: theme.shadows[10],
  },
  marginBottom: theme.spacing(4),
  height: '100%',
}));

const CardMedia = styled('img')(({ theme }) => ({
  height: '200px',
  width: '100%',
  objectFit: 'cover',
}));

const TestimonialCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#fff',
  borderRadius: '10px',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
  marginBottom: theme.spacing(2),
  height: '100%',
}));

const TestimonialAvatar = styled('img')(({ theme }) => ({
  width: '50px',
  height: '50px',
  borderRadius: '50%',
  marginRight: theme.spacing(2),
  objectFit: 'cover',
}));

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  borderRadius: '10px',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
  '&:before': {
    display: 'none',
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme, expanded }) => ({
  backgroundColor: expanded ? theme.palette.action.hover : theme.palette.background.paper,
  fontWeight: expanded ? 'bold' : 'normal',
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));


const FAQSection = () => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleToggleShowMore = () => {
    setShowMore((prev) => !prev);
  };

  return (
    <Box sx={{ backgroundColor: theme.palette.background.default, py: 8 }}>
      <Container>
        <Typography variant="h4" component="h2" gutterBottom>
          Frequently Asked Questions
        </Typography>
        <StyledAccordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <StyledAccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" expanded={expanded === 'panel1'}>
            <Typography>What actually is Don’t Forget Grandma?</Typography>
          </StyledAccordionSummary>
          <AccordionDetails>
            <StyledTypography>
              We send you SMS reminders to keep in touch with your loved ones. You enter the people important to you, how often you’d like to keep in touch with them, and, if you’d like to, their birthday. There’s also space for notes for each person.
              We use your list to send you SMS reminders, which will look something like this: 
            </StyledTypography>
            <MessageBubble>
              Today, reach out to Aunty Ange and Jenni (birthday).
            </MessageBubble>
            <StyledTypography
              fontStyle="italic"
              onClick={handleToggleShowMore}
              sx={{ cursor: 'pointer', color: theme.palette.primary.main, textDecoration: 'underline' }}
            >
              ‘Wait… that’s all? Can’t I use notes/calendar/… to do the same thing?’ 
            </StyledTypography>
            {showMore && (
              <>
                <StyledTypography>
                  If that works for you, I recommend it! I built Don’t Forget Grandma because I need an extra nudge. I know an SMSs aren’t much, but for me they’re just enough. I hope they’ll help you build closer relationships too.
                </StyledTypography>
                <StyledTypography>
                  I have a couple other reasons why I love Don’t Forget Grandma:
                </StyledTypography>
                <StyledTypography>
                  • <strong>It’s a bit random</strong>. Unlike a calendar reminder, it doesn’t just remind me to call Grandma on the last Friday of every month. It has a bit of randomness, so it feels more organic.
                </StyledTypography>
                <StyledTypography>
                  • <strong>It actually works for me</strong>. When I used to finally remember to call my Grandma, I’d remember all the other relationships I was neglecting. Usually, this included my brother, my cousins, and my old school friends. Don’t Forget Grandma just gives me one or two people to call every day. It feels more manageable.
                </StyledTypography>
              </>
            )}
          </AccordionDetails>
        </StyledAccordion>
        <StyledAccordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <StyledAccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header" expanded={expanded === 'panel2'}>
            <Typography>How often will I get SMSs?</Typography>
          </StyledAccordionSummary>
          <AccordionDetails>
            <StyledTypography>
              We will text you just often enough to make sure you’re staying in touch with everyone as regularly as you’d like. For instance, if you have 5 contacts and want to talk to each once a week, you’ll receive an SMS around 5 days a week. There’s a bit of randomness though, so it will vary from week to week.
            </StyledTypography>
            <StyledTypography>
              If you have lots of contacts or want to talk to your contacts very regularly, we will SMS you every day. In this case, we might text you more than one person to reach out to on some days. For instance, if you have 8 contacts and want to talk to each of them once a week, we’ll SMS you every day, and about once a week we’ll send you two people to reach out to in your daily text.
            </StyledTypography>
          </AccordionDetails>
        </StyledAccordion>
        <StyledAccordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
          <StyledAccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header" expanded={expanded === 'panel3'}>
            <Typography>How much does it cost?</Typography>
          </StyledAccordionSummary>
          <AccordionDetails>
            <StyledTypography>
            The current price is shown during sign up. You also get a week free trial.
            </StyledTypography>
          </AccordionDetails>
        </StyledAccordion>
      </Container>
    </Box>
  );
};

const LandingPage = ({ onSignUp }) => {
  const theme = useTheme();

  const testimonials = [
    {
      avatar: "https://source.unsplash.com/random",
      text: "This app has helped me reconnect with friends and family in ways I never thought possible! It's become an essential part of my daily routine. I can't imagine life without it now. - Happy User"
    },
    {
      avatar: "https://source.unsplash.com/random",
      text: "A must-have for anyone who wants to keep their relationships strong. I've never felt more connected to my loved ones. The reminders are perfectly timed and the app is so easy to use. - Satisfied Customer"
    },
    {
      avatar: "https://source.unsplash.com/random",
      text: "Using this app has been a game-changer for my relationships. I no longer forget important dates and always reach out at the right time. Highly recommended! - Grateful User"
    },
    {
      avatar: "https://source.unsplash.com/random",
      text: "I love how this app helps me stay in touch with everyone I care about. It's like having a personal assistant that keeps track of my relationships. Fantastic tool! - Loyal User"
    },
  ];

  return (
    <>
      <HeroSection>
        <HeroText>
          <HeroImage style={{ width: '80%', height: 'auto', maxWidth: '500px', marginBottom: '20px' }} />
          <Typography variant="h2" component="h1" gutterBottom>
            Stay Connected
          </Typography>
          <Typography variant="h5" component="h2" gutterBottom>
            Get personalized reminders to stay in touch and never miss a special moment.
          </Typography>
          <CustomButton variant="contained" size="large" onClick={onSignUp} sx={{ mt: 2 }}>
            Sign Up Now
          </CustomButton>
        </HeroText>
      </HeroSection>
      <Section>
        <Container>
          <Typography variant="h4" component="h2" gutterBottom>
            How It Works
          </Typography>
          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} sm={6} md={4}>
              <CustomCard>
                <CardMedia src={`${process.env.PUBLIC_URL}/ff.jpg`} title="Add Your Contacts" />
                <CardContent>
                  <Typography variant="h6" component="h3" gutterBottom>
                    Step 1: Add Your Contacts
                  </Typography>
                  <Typography>
                    Input the people you want to stay in touch with, including their birthdays and preferred contact frequency.
                  </Typography>
                </CardContent>
              </CustomCard>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CustomCard>
                <CardMedia src={`${process.env.PUBLIC_URL}/dataiscool.jpg`} title="Get Reminders" />
                <CardContent>
                  <Typography variant="h6" component="h3" gutterBottom>
                    Step 2: Get Reminders
                  </Typography>
                  <Typography>
                    Our smart algorithm will send you reminders to reach out to your contacts based on your preferences.
                  </Typography>
                </CardContent>
              </CustomCard>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CustomCard>
                <CardMedia src={`${process.env.PUBLIC_URL}/happycouple.jpg`} title="Stay Connected" />
                <CardContent>
                  <Typography variant="h6" component="h3" gutterBottom>
                    Step 3: Stay Connected
                  </Typography>
                  <Typography>
                    Enjoy meaningful connections with the important people in your life and never miss a special moment.
                  </Typography>
                </CardContent>
              </CustomCard>
            </Grid>
          </Grid>
        </Container>
      </Section>
      <FAQSection />
      {/* 
      <FullWidthSection>
        <Container>
          <Typography variant="h4" component="h2" gutterBottom>
            What Our Users Say
          </Typography>
          <Carousel
            navButtonsAlwaysVisible={true}
            interval={5000}
            animation="slide"
            indicators={false}
          >
            {testimonials.map((testimonial, index) => (
              <Grid container spacing={4} justifyContent="center" key={index}>
                {testimonials.slice(index, index + 2).map((item, i) => (
                  <Grid item xs={12} sm={6} md={4} key={i}>
                    <TestimonialCard>
                      <TestimonialAvatar src={item.avatar} alt="User" />
                      <Typography variant="body1" gutterBottom>
                        {item.text}
                      </Typography>
                    </TestimonialCard>
                  </Grid>
                ))}
              </Grid>
            ))}
          </Carousel>
        </Container>
      </FullWidthSection>
      */}
      <Section>
        <Container>
          <Typography variant="h4" component="h2" gutterBottom>
            About Me
          </Typography>
          <Paper elevation={3} sx={{ p: 4, maxWidth: '800px', margin: 'auto' }}>
            <Typography variant="body1" gutterBottom>
              I created Don't Forget Grandma out of my own need to stay connected with my loved ones. It started as a personal project to ensure I never missed a birthday or a special moment with my family and friends.
            </Typography>
            <Typography variant="body1" gutterBottom>
              My friends saw the impact it had on my relationships and insisted that I share it with them. Now, I'm excited to bring this tool to you, in the hope that it helps you stay connected with the important people in your life too.
            </Typography>
            <Typography variant="body1">
              I am dedicated to improving human connection through simple, effective reminders. I found the simplest innovations make the most difference for me. I hope Don't Forget Grandma becomes an essential part of your life by helping you feel on top of your relationships, just as it has for me.
            </Typography>
          </Paper>
        </Container>
      </Section>
    </>
  );
};

export default LandingPage;
