import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, MenuItem } from '@mui/material';
import moment from 'moment-timezone';

function UserInfoEditForm({ currentInfo, onSubmit }) {
  const [userInfo, setUserInfo] = useState(currentInfo);
  const timezones = moment.tz.names();

  useEffect(() => {
    setUserInfo(currentInfo);
  }, [currentInfo]);

  const handleChange = (event) => {
    setUserInfo({ ...userInfo, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(userInfo);
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <TextField
        name="user_name"
        label="Name"
        value={userInfo.user_name}
        onChange={handleChange}
        required
      />
      <TextField
        name="user_mobile_number"
        label="Phone Number"
        value={userInfo.user_mobile_number}
        onChange={handleChange}
        required
      />
      <TextField
        name="desired_contact_time"
        label="Preferred SMS Time"
        type="time"
        value={userInfo.desired_contact_time}
        onChange={handleChange}
        InputLabelProps={{ shrink: true }}
        inputProps={{ step: 900 }} // 15 min intervals
        required
      />
      <TextField
        name="user_tz"
        label="Timezone"
        select
        value={userInfo.user_tz}
        onChange={handleChange}
        helperText="Please select your timezone"
        required
        >
          {timezones.map((tz) => (
            <MenuItem key={tz} value={tz}>{tz}</MenuItem>
          ))}
      </TextField>

      <Button type="submit">Update Info</Button>
    </Box>
  );
}

export default UserInfoEditForm;
