// NewUserDetails.js
import React from 'react';
import { Box, Typography, Grid, Link } from '@mui/material';
import UserInfoForm from './UserInfoForm';

const HelpText = () => (
  <Box sx={{
    position: 'fixed',
    bottom: 0,
    right: 0,
    padding: 2,
    bgcolor: 'background.paper',
    color: 'primary.main',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid',
    borderColor: 'divider',
  }}>
    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
      need help? <Link href="mailto:hello@dfg-app.com" sx={{ color: 'inherit', textDecoration: 'none' }}>hello@dfg-app.com</Link>
    </Typography>
  </Box>
);

const NewUserPage = ({ session, onSubmit}) => {
  return (
    <Box sx={{ my: 3, mx: 3, justifyContent: 'center' }}>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Typography variant="h4" component="h1">
            New User Details
          </Typography>
        </Grid>
      </Grid>
      <Typography variant="subtitle1" sx={{ mb: 2 }}>
        This is the information we need to be able to send you daily updates.
      </Typography>
      <UserInfoForm session={session} onSubmit={onSubmit} />
      <HelpText />
    </Box>
  );
};

export default NewUserPage;
