import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#6a994e',
    },
    secondary: {
      main: '#f2e8cf',
    },
  },
  components: {
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: '#fffdf7',
          },
          '&:hover': {
            backgroundColor: '#f0e2c2',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: '#e9ffe1',
          backgroundColor: '#6a994e',
          '&:hover': {
            color: '#6a994e',
            backgroundColor: '#f2e8cf',
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          // Your styles here
        },
      },
    },
  },
});

export default theme;
