import React from 'react';
import { Box, Typography, Button, Grid, TextField, Container, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import UserInfoEditForm from './UserInfoEditForm';

const Section = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4, 2),
  marginBottom: theme.spacing(4),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
}));

const Settings = ({ userInfo, isEditingUserInfo, onEditUserInfo, onUserInfoUpdate, redirectToStripeBilling }) => {
  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Settings
      </Typography>

      <Section>
        <Typography variant="h5" component="h2" gutterBottom>
          Manage Subscription
        </Typography>
        <Button variant="contained" color="secondary" onClick={redirectToStripeBilling}>
          Manage Subscription
        </Button>
      </Section>

      <Section>
        <Typography variant="h5" component="h2" gutterBottom>
          Your User Information
          {!isEditingUserInfo && (
            <IconButton color="primary" onClick={onEditUserInfo} sx={{ ml: 1 }}>
              <EditIcon />
            </IconButton>
          )}
        </Typography>
        {isEditingUserInfo ? (
          <UserInfoEditForm currentInfo={userInfo} onSubmit={onUserInfoUpdate} />
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1"><strong>User Name:</strong> {userInfo.user_name}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1"><strong>Mobile Number:</strong> {userInfo.user_mobile_number}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1"><strong>Daily Contact Time:</strong> {userInfo.desired_contact_time || 'Not set'}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1"><strong>Timezone:</strong> {userInfo.user_tz || 'Not set'}</Typography>
            </Grid>
          </Grid>
        )}
      </Section>
    </Container>
  );
};

export default Settings;
