import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LandingPage from './LandingPage';
import AuthPage from './AuthPage';
import NewUserPage from './NewUserPage';
import ContactForm from './ContactForm';
import ContactsTable from './ContactsTable';
import Settings from './Settings';
import PrivacyPolicyPage from './PrivacyPolicyPage';
import TermsOfServicePage from './TermsOfServicePage';

const AppRoutes = ({
  session,
  userInfo,
  isFormVisible,
  handleSignUp,
  handleUserInfoSubmit,
  handleLogout,
  handleSubmit,
  editingContact,
  handleCancelEdit,
  handleShowAddForm,
  contacts,
  handleShowEditForm,
  handleDelete,
  isEditingUserInfo,
  handleEditUserInfo,
  handleUserInfoUpdate,
  redirectToStripeBilling
}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (session !== undefined && userInfo !== undefined) {
      setLoading(false);
    }
  }, [session, userInfo]);

  if (loading) {
    return <div>Loading...</div>; // Replace with a loading spinner or a more user-friendly loading indicator
  }
  return (
    <Routes>
    {!session ? (
      <>
        <Route path="/" element={<LandingPage onSignUp={handleSignUp} />} />
        <Route path="/signup" element={<AuthPage mode="signup" />} />
        <Route path="/login" element={<AuthPage mode="login" />} />
        <Route path="*" element={<Navigate to="/" />} />
      </>
    ) : !userInfo ? (
      <>
        <Route path="/new-user" element={<NewUserPage session={session} onSubmit={handleUserInfoSubmit} onLogout={handleLogout} />} />
        <Route path="*" element={<Navigate to="/new-user" />} />
      </>
    ) : (
      <>
        <Route path="/" element={
          <Box sx={{ my: 3, mx: 3 }}>
            {isFormVisible && (
              <Box sx={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: 10, backgroundColor: '#fff', padding: 2 }}>
                <ContactForm onSubmit={handleSubmit} editingContact={editingContact} onCancel={handleCancelEdit} />
              </Box>
            )}
            <Button fullWidth variant="contained" color="primary" onClick={handleShowAddForm} sx={{ mb: 2 }}>
              Add New Contact
            </Button>
            <ContactsTable contacts={contacts} onEdit={handleShowEditForm} onDelete={handleDelete} />
          </Box>
        } />
        <Route path="/settings" element={
          <Settings
            userInfo={userInfo}
            isEditingUserInfo={isEditingUserInfo}
            onEditUserInfo={handleEditUserInfo}
            onUserInfoUpdate={handleUserInfoUpdate}
            redirectToStripeBilling={redirectToStripeBilling}
          />
        } />
        <Route path="*" element={<Navigate to="/" />} />
      </>
    )}
    <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
    <Route path="/terms-of-service" element={<TermsOfServicePage />} />
  </Routes>
);
};

export default AppRoutes;
