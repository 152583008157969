// UserInfoForm.js
import moment from 'moment-timezone';
import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, MenuItem } from '@mui/material';
import { Stepper, Step, StepLabel, Typography } from '@mui/material';



function UserInfoForm({ session, onSubmit }) {
  const [userInfo, setUserInfo] = useState({ user_name: '', user_mobile_number: '' , desired_contact_time: '17:00', user_tz: ''}); // Default time set to 17:00 (5 PM) in 24-hour format
  const timezones = moment.tz.names();
  const [isStripeCompleted, setIsStripeCompleted] = useState(false); //TODO: make this check in the DB (using serverless function) whether the user has an active subscription
  const [verificationCode, setVerificationCode] = useState('');
  const [isPhoneVerified, setIsPhoneVerified] = useState(false);
  const [verificationMessage, setVerificationMessage] = useState(''); // Add this line
  const [lastSentTime, setLastSentTime] = useState(null);
  const [countdown, setCountdown] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const steps = ['Get started with Stripe', 'Your details', 'Phone verification'];

  const handleNext = () => {
    if (activeStep === 1 && !validateStepTwo()) {
      return; // Prevent advancing if second step validation fails
    }  
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setVerificationMessage(''); // Clear message if returning a step
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

    // useEffect to check URL for the success parameter
    useEffect(() => {
      const queryParams = new URLSearchParams(window.location.search);
      const success = queryParams.get('success');
      if (success === 'true') {
        setIsStripeCompleted(true);
      }
    }, []);
  
  const handleChange = (event) => {
    setUserInfo({ ...userInfo, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(userInfo);
  };

  const handleSubscription = async () => {
    const payload = {
      priceId: 'price_1OdFVKBzm91nLBIArmxr8P8d', // Replace with your price ID
      userId: session.user.id, // User ID from your app
      userEmail: session.user.email // User ID from your app
    };
    console.log('Subscription Payload:', payload);

    const response = await fetch('/api/create-subscription-session', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
    const { url } = await response.json();
    window.location.href = url;
  };
  
  // Twilio functions
  const sendVerificationCode = async () => {
    const now = Date.now();
    const timePassed = lastSentTime ? now - lastSentTime : null;
  
    if (timePassed === null || timePassed >= 60000) {
      try {
        const response = await fetch('/api/send-verification-sms', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ phoneNumber: userInfo.user_mobile_number }),
        });
  
        if (!response.ok) {
          throw new Error('Failed to send verification code');
        }
  
        // Verification code sent successfully, so update the last sent time
        setLastSentTime(now);
        // Start the countdown
        setCountdown(60);
      } catch (error) {
        // If there's an error sending the code, inform the user
        setVerificationMessage('Error sending verification code.');
      }
    } else {
      // If the user tries to send a code again before 60 seconds, let them know how long to wait
      const timeLeft = Math.round((60000 - timePassed) / 1000);
      setVerificationMessage(`Please wait ${timeLeft} seconds before requesting a new code.`);
    }
  };
  
  //countdown timer on sending new verification code
  useEffect(() => {
    let interval = null;
  
    if (countdown > 0 && !isPhoneVerified) {
      setVerificationMessage(`Verification code sent. Please check your SMS. You may request a new code in ${countdown} seconds.`);
      interval = setInterval(() => {
        setCountdown((currentCountdown) => {
          // When decrementing, also update the message with the new countdown time
          if (currentCountdown - 1 > 0) {
            setVerificationMessage(`Verification code sent. Please check your SMS. You may request a new code in ${currentCountdown - 1} seconds.`);
          }
          return currentCountdown - 1;
        });
      }, 1000);
    } else if (countdown === 0 && !isPhoneVerified) {
      // When countdown finishes, change the message to allow for a new code request
      setVerificationMessage('You can now request a new verification code.');
      setCountdown(null); // Reset countdown
    }
  
    // Clean up the interval on unmount
    return () => clearInterval(interval);
  }, [countdown]);
    
  const verifyCode = async () => {
    try {
      const response = await fetch('/api/verify-code', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ phoneNumber: userInfo.user_mobile_number, code: verificationCode }),
      });

      const data = await response.json();
      if (data.verified) {
        setIsPhoneVerified(true);
        setVerificationCode('');
        setVerificationMessage('Phone number verified successfully!');
        setCountdown(null);
      } else {
        setVerificationMessage('Verification failed. Incorrect code.');
      }
    } catch (error) {
      setVerificationMessage('Error verifying code.');
    }
  }

  //autoadvance after stripe success
  useEffect(() => {
    if (isStripeCompleted && activeStep === 0) {
      // Set a timeout for 0.5 to 1 second before advancing
      const timer = setTimeout(() => {
        handleNext();
      }, 800); // 800 milliseconds delay
  
      return () => clearTimeout(timer); // Clean up the timer
    }
  }, [isStripeCompleted, activeStep, handleNext]);
    
  //Check step 2 complete
  const validateStepTwo = () => {
    if (!userInfo.user_name.trim()) {
      setVerificationMessage('Please enter your name.');
      return false;
    }
    if (!userInfo.desired_contact_time.trim()) {
      setVerificationMessage('Please enter your desired contact time.');
      return false;
    }
    if (!userInfo.user_tz.trim()) {
      setVerificationMessage('Please enter your timezone.');
      return false;
    }  
    if (activeStep === 1) {
      setVerificationMessage(''); // Clear message if all checks pass
    }

    return true; // Return true if all validations pass
  };
  
  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Box sx={{ mb: 4, mt: 4 }}>
          <Typography variant="h6" component="h3" sx={{ mb: 1 }}>
            Head to Stripe to sign up. Your first week is free!
          </Typography>
          <Button 
            type="button" 
            fullWidth 
            variant="contained" 
            onClick={handleSubscription}
            disabled={isStripeCompleted}
            sx={{ py: 1 }}
          >
            Activate your subscription with Stripe
          </Button>
        </Box>
              );
      case 1:
        return (
          <Box sx={{ mb: 4 }}>
          <Box sx={{ mb: 4 }}>
          <TextField
              name="user_name"
              label="Your name"
              value={userInfo.user_name}
              onChange={handleChange}
              required
              fullWidth
              variant="outlined"
              margin="normal"
            />
        </Box>
        <Box sx={{ mb: 4 }}>
          <TextField
            name="desired_contact_time"
            label="Preferred SMS Time"
            type="time"
            value={userInfo.desired_contact_time}
            onChange={handleChange}
            InputLabelProps={{ shrink: true }}
            inputProps={{ step: 900 }} // 15 min intervals
            required
            fullWidth
            variant="outlined"
            margin="normal"
          />
        </Box>
        <Box sx={{ mb: 4 }}>
          <TextField
            name="user_tz"
            label="Timezone"
            select
            value={userInfo.user_tz}
            onChange={handleChange}
            required
            fullWidth
            variant="outlined"
            margin="normal"
          >
            {timezones.map((tz) => (
              <MenuItem key={tz} value={tz}>{tz}</MenuItem>
            ))}
          </TextField>
        </Box>
        {verificationMessage && (
          <Box sx={{ mb: 4 }}>
            <Typography variant="body2" color="error" sx={{ mb: 2 }}>
              {verificationMessage}
            </Typography>
           </Box>

          )}


        </Box>        );
      case 2:
        return (
          <Box sx={{ mb: 4 }}>
          <Box sx={{ mb: 4 }}>
            <Typography variant="body2" sx={{ mb: 2, mt: 4 }}>
              Make sure you include your international area code, otherwise the SMSs will not work :). <br/> 
              USA numbers don't work at the moment (sorry!)
            </Typography>
            <TextField
              name="user_mobile_number"
              label="Phone Number"
              value={userInfo.user_mobile_number}
              onChange={handleChange}
              required
              fullWidth
              variant="outlined"
              margin="normal"
            />
            <Button 
              variant="contained" 
              onClick={sendVerificationCode}
              disabled={isPhoneVerified || (lastSentTime && Date.now() - lastSentTime < 60000)}
              sx={{ mt: 2, py: 1 }}
            >
              Send Verification Code
            </Button>
          </Box>
      
          <Box sx={{ mb: 4 }}>
            <TextField
              name="verification_code"
              label="Verification Code"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              fullWidth
              variant="outlined"
              margin="normal"
            />
            <Button 
              variant="contained" 
              onClick={verifyCode}
              disabled={!verificationCode || isPhoneVerified}
              sx={{ mt: 2, py: 1 }}
            >
              Verify Code
            </Button>
          </Box>
      
        <Box sx={{ mb: 4, p: 2, border: '1px solid', borderColor: 'primary.main', borderRadius: '4px', backgroundColor: 'background.paper' }}>
          <Typography variant="body1" color="text.primary" textAlign="center">
            {verificationMessage}
          </Typography>
          </Box>      
        </Box>        );
      default:
        return 'Unknown step';
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
    <Stepper activeStep={activeStep} alternativeLabel>
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
    <form onSubmit={handleSubmit}>
      {getStepContent(activeStep)}
      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, justifyContent: 'flex-end' }}>
        {activeStep !== 0 && (
          <Button
            color="inherit"
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            Back
          </Button>
        )}

        {activeStep < steps.length - 1 ? (
          <Button 
            variant="contained" 
            onClick={handleNext}
            disabled={activeStep === 0 && !isStripeCompleted}>
            Next
          </Button>
        ) : (
          <Button 
            type="submit" 
            variant="contained" 
            disabled={!isStripeCompleted || !isPhoneVerified}
          >
            Submit
          </Button>
        )}
      </Box>
    </form>
  </Box>
  );
}

export default UserInfoForm;
